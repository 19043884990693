// ! ---------- ICONS


%icon {
    font-family: $font-icons !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale; }

[class^="icon-"], [class*=" icon-"] {
    @extend %icon; }


// Generate icon classnames from code map (see _variables.scss)

@each $name, $glyph in $icon-codes {
    .icon-#{$name}:before {
        content: icon-code($name); } }
