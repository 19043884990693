// ! ---------- NAMED BREAKPOINTS / LAYOUTS

@mixin layout--nav-always-visible {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--nav-as-overlay {
    @include breakpoint(small down) {
        @content; } }

@mixin layout--multi-panels-in-slideshow {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--single-panels-in-slideshow {
    @include breakpoint(small down) {
        @content; } }

@mixin layout--project-list-as-table {
    @include breakpoint(large) {
        @content; } }



@mixin header-height-property($property, $factor: 1) {
    @include breakpoint-map-property($property, $header-height, $factor); }



%nav-toggle-stroke-height {
    height: $nav-toggle-stroke;
    @include breakpoint(xxlarge) {
        height: $nav-toggle-stroke * 1.5; } }

%nav-toggle-button {
    @extend %neutral-button;
    @extend %no-tap-highlight;
    position: relative;
    width: $nav-toggle-width;
    height: $nav-toggle-width;
    cursor: pointer;
    transform: scale($nav-toggle-scale);

    span {
        @extend %hide-visually; }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        @extend %nav-toggle-stroke-height;
        background: currentColor;
        transform: translateY(-50%); } }

%burger-button {
    @extend %nav-toggle-button;

    &::before {
        display: none; }

    &::after {
        box-shadow: 0 -#{$nav-toggle-height/2} 0 0 currentColor, 0 #{$nav-toggle-height/2} 0 0 currentColor; } }

%close-button {
    @extend %nav-toggle-button;
    transform: rotate(-46deg) scale($nav-toggle-scale);

    &::before,
    &::after {
        width: 100%; }
    &::before {
        transform: scaleX(1.25) translateY(-50%); }
    &::after {
        transform: rotate(90deg) scaleX(1.25) translateY(-50%); } }

%checkbox-label {
    &::before {
        display: inline-block;
        content: ' ';
        width: 1.2em;
        height: 1.2em;
        border: .15em solid;
        margin-right: .5em;
        text-align: center;
        line-height: .8;
        font-size: .7em;
        transition: transition(background color border-color);
        transform: translateY(-.1em);
        padding-top: .2em; } }

%checkbox-label--hover {
    &::before {
        background: palette('text-light');
        background: palette('highlight');
        border-color: palette('highlight');
        color: palette('background'); } }

%checkbox-label--checked {
    &::before {
        content: '×';
        background: palette('highlight');
        border-color: palette('highlight');
        color: palette('background'); } }
