// ! ---------- TYPOGRAPHY


%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base;
    font-variant-numeric: oldstyle-nums proportional-nums; }

@mixin fontsize-xxs {
    font-size: $fontsize-xxs;
    @include breakpoint(medium) {
        font-size: $fontsize-xxs--desktop; } }

@mixin fontsize-xs {
    font-size: $fontsize-xs;
    @include breakpoint(medium) {
        font-size: $fontsize-xs--desktop; } }

@mixin fontsize-s {
    font-size: $fontsize-s;
    @include breakpoint(medium) {
        font-size: $fontsize-s--desktop; } }

@mixin fontsize-m {
    font-size: $fontsize-m;
    @include breakpoint(medium) {
        font-size: $fontsize-m--desktop; } }

@mixin fontsize-l {
    font-size: $fontsize-l;
    line-height: $lineheight-s;
    @include breakpoint(medium) {
        font-size: $fontsize-l--desktop; }
    font-weight: 200; }

@mixin fontsize-xl {
    font-size: $fontsize-xl;
    @include breakpoint(medium) {
        font-size: $fontsize-xl--desktop; }
    font-weight: 200; }

%fontsize-xxs {
    @include fontsize-xxs; }
%fontsize-xs {
    @include fontsize-xs; }
%fontsize-s {
    @include fontsize-s; }
%fontsize-m {
    @include fontsize-m; }
%fontsize-l {
    @include fontsize-l; }
%fontsize-xl {
    @include fontsize-xl; }

@mixin underline-style {
    text-decoration: none;
    background-image: linear-gradient(transparent 30%, currentColor 30%);
    background-repeat: repeat-x;
    background-position: 0 89%;
    background-size: 1px .12em;
    padding-bottom: .1em;
    .safari & {
        background-size: 1px .1em; }
    .ios & {
        background-size: 1px .07em;
        background-position: 0 86%; }

    // Disable for IEdge

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: none;
        text-decoration: underline; } }

@mixin underline-style--large {
    background-size: 1px .09em;
    padding-bottom: .1em;
    .safari & {
        background-size: 1px .08em; }
    .ios & {
        background-size: 1px .08em; } }

@mixin underline-style--remove {
    background-image: none !important; }

%underline-style {
    @include underline-style; }

%underline-style--large {
    @include underline-style--large; }
