// ! ---------- LAYOUT


body {
    &.template-home {
        overflow: hidden; } }

.template-gallery-page {
    .page__header,
    .page__navigation {
        display: none; } }



// ! ---------- HEADER


.page__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-index-header;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    @include global-padding;
    @include global-padding(top, $factor: $padding-top-factor);

    transition: transition(padding);

    .logo {
        flex: 0 0 percentage(3/12);
        .page-style--hidden-site-name & {
            visibility: hidden; } }

    .navigation-toggle {
        flex: 0 0 auto;
        margin-left: auto; }

    .project-information {
        flex: 0 0 100%;
        padding-top: $one-line-height;
        pointer-events: none; }

    @include breakpoint(medium) {
        .logo {
            flex: 0 0 percentage(3/12);
            order: 1; }
        .project-information {
            flex: 1 1 percentage(7/12);
            order: 2;
            padding-top: 0; }
        .navigation-toggle {
            order: 3; } } }


.page__navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: palette('background');
    transition: transition(opacity padding);
    opacity: 0;
    pointer-events: none;
    z-index: $z-index-navigation;
    @extend %scrollable;

    @include global-padding;
    @include global-padding(top, $factor: $padding-top-factor);

    .is-navigation-visible & {
        opacity: 1;
        pointer-events: auto; } }


// ! ---------- CONTENT


.page__content {
    position: relative;
    z-index: $z-index-content;

    .template-basic-page &,
    .template-about &,
    .template-project-list &,
    .template-newsletter &, {
        @include global-padding;
        @include global-padding(top, $factor: $padding-top-factor);
        transition: transition(padding); }

    .is-navigation-visible & {
        pointer-events: none; }

    .page-style--small-text & {
        @extend %fontsize-s; } }

.project-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-content; }


// ! ---------- SITE LINK


.page__site-link {
    position: fixed;
    bottom: .1em;
    right: .1em;
    // top: calc(100vh + 1.925em)
    transform: translateX(100%) rotate(-90deg);
    transform-origin: 0% 100%;
    transition: transition(color, $factor: 1/2);
    font-size: $fontsize-xxl;
    line-height: 0;
    z-index: $z-index-site-link;

    .template-gallery-page & {
        color: palette('highlight'); }

    .template-home & {
        color: transparent;
        pointer-events: none; }

    .is-navigation-visible & {
        color: palette('text');
        pointer-events: auto; }

    a {
        @extend %fontsize-m;
        line-height: $lineheight-m;
        display: inline-block;
        transform: translateY(.4em); } }
