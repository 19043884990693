// ! ---------- TEXT BLOCKS


// These apply wherever users enter rich-text content themselves

.text-block {

    a, .a, u {
        @extend %underline-style; }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: $one-line-height; }

    p, ul, ol, figure, img, table, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height;
        &:last-child {
            margin-bottom: 0; } }

    figure img {
        margin-bottom: 0; }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .5em;
                content: "•"; } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .4em;
                content: counter(table-ol) "."; } } } }
