// ! ---------- HTML & BODY


html {
    @extend %basic-typography;
    box-sizing: border-box;
    font-size: $fontsize-base;
    @include breakpoint(xlarge) {
        font-size: $fontsize-base-vw; }
    cursor: default; }

*,
*::before,
*::after {
    box-sizing: inherit; }

body {
    background: palette('background');
    color: palette('text');
    @extend %fontsize-m;
    line-height: $lineheight-m;
    text-size-adjust: none;

    overflow-x: hidden;

    @if $font-smoothing {
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale; } }



// ! ---------- BASIC STYLES


a {
    color: inherit;
    text-decoration: none;
    transition: transition(color);

    &:hover, &.hover {
        text-decoration: none;
        color: inherit; } }

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

input,
td {
    @extend %basic-typography;
    font-size: 1em; }

::placeholder {
    line-height: normal; }

select {
    width: 100%; }



// ! ---------- TEXT SELECTION


::selection {
    background: palette('selection-bg');
    color: palette('selection-text');
    .template-about & {
        background: palette('selection-text');
        color: palette('selection-bg'); } }


// ! ---------- HELPERS


.hide-visually,
.sr-only {
    @extend %hide-visually; }

.hide-text {
    @extend %hide-text; }

// Show/hide elements in JavaScript

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.is-loading,
.is-loading input,
.is-loading a {
    cursor: wait !important; }

.clearfix {
    @include clearfix; }


// ! ---------- IMAGE LAZYLOADING


// Fade in after load

.lazyload,
.lazyloading {
    opacity: 0; }

.lazyloaded {
    opacity: 1;
    transition: transition(opacity); }


// Blur lazyloaded images before unveiling

.lazyloadblur {
    opacity: 1;
    filter: blur(20px);
    transition: transition(filter);

    &.lazyloaded {
        filter: blur(0); } }


// Hide lazyload images on non JS browsers

.no-js .lazyload {
    display: none !important; }

.no-js noscript .lazyload {
    display: block !important;
    opacity: 1; }



// ! ---------- INTRINSIC RATIOS


.ratio-container {
    position: relative;
    height: 0;
    max-width: 100%;
    overflow: hidden;

    img, video, iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.ratio-1-1 {
    padding-bottom: 100%; }
.ratio-3-2 {
    padding-bottom: 66.6%; }
.ratio-4-3 {
    padding-bottom: 75%; }
.ratio-5-3 {
    padding-bottom: 60%; }
.ratio-5-4 {
    padding-bottom: 80%; }
.ratio-16-9 {
    padding-bottom: 56.25%; }
.ratio-16-10,
.ratio-8-5 {
    padding-bottom: 62.5%; }
