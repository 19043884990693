/* Reset.css
   ***************************************************************************/


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, tt,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, progress, mark, audio, var, video, xmp {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section, summary {
	display: block; }


html,
body {
	height: 100%; }


body {
	line-height: 1; }


ul {
	list-style: none; }


ol {
	list-style: decimal inside none; }



li {
	display: list-item; }


b, strong {
	font-weight: bold; }


i, em {
	font-style: italic; }


img {
	color: transparent;
	font-size: 0;
	vertical-align: middle;
	-ms-interpolation-mode: bicubic; }


blockquote, q {
	quotes: none; }


blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none; }


table {
	border-collapse: collapse;
	border-spacing: 0; }


th, td, caption {
	vertical-align: top;
	text-align: left; }


sub, sup, small {
	font-size: 75%; }


sub, sup {
	line-height: 0;
	position: relative;
	vertical-align: baseline; }


sub {
	bottom: -0.25em; }


sup {
	top: -0.5em; }


svg {
	overflow: hidden; }
