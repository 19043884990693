// ! ---------- JS CONFIG


// Share config values with JS by setting font-family of meta tags
// Supports media queries (config is re-read on window resize)


// Breakpoints as serialized map

.js-config-breakpoints {
    font-family: '#{serialize-map($breakpoints)}'; }


// Transition duration in milliseconds

.js-config-transition {
    $duration: to-unit(ms, $transition-duration);
    $duration: strip-unit($duration);
    font-family: '#{$duration}'; }
