// ! ---------- BASIC PAGE


body.template-basic-page {
    background: palette('text');
    color: palette('background'); }

.basic-page {
    max-width: $text-max-width;

    .page-style--small-text & {
        max-width: $text-max-width--small; } }


.basic-page,
.about,
.newsletter, {
    padding-top: 2 * $one-line-height;

    .page-style--hidden-site-name & {
        padding-top: 0 !important; } }

.basic-page__title,
.about__title {
    margin-bottom: $one-line-height;
    .page-style--hidden-page-title & {
        display: none; } }

.basic-page__content,
.about__content {
    margin-bottom: 2 * $one-line-height; }

.basic-page__content {
    @include global-padding(right, $factor: 2); }

.inline-hover-image {
    img {
        display: none; } }



// ! ---------- ABOUT


body.template-about {
    background: palette('highlight');
    color: palette('background'); }

.about {
    @include grid-row; }

.about__main,
.about__secondary {
    @include grid-column($gutters: 0); }

.about__main {
    @extend %fontsize-l;
    margin-bottom: 1 * $one-line-height;
    @include global-padding(right, $factor: 2);
    .text-block {
        max-width: $text-max-width; } }

.about__secondary {
    margin-bottom: 1 * $one-line-height;
    @include breakpoint(medium) {
        @include grid-column-size(4);
        @include grid-gutter-padding(right);
        &:last-child {
            @include grid-gutter-padding(right, $factor: 0); } } }


.about .text-block {

    p {
        margin-bottom: $one-line-height / 2; }

    h1, h2, h3, h4, h5, h6 {
        display: inline-block;
        // @extend %underline-style
        margin-top: $one-line-height;
        &:first-child {
            margin-top: 0; }
        & + p,
        & + ul,
        & + ol {} } }



// ! ---------- NEWSLETTER


.newsletter {}

.newsletter__title {
    @extend %hide-visually; }

.newsletter__headline {
    .newsletter--success & {
        @extend %hide-visually; } }

.newsletter__feedback {
 }    // margin-bottom: $one-line-height

.newsletter__form {
    margin-top: $one-line-height; }



// ! ---------- NEWSLETTER FORM


.newsletter-form {

    max-width: $text-max-width;

    label {
        p {
            display: inline; } }

    .newsletter-form__input-wrapper:hover {
        label {
            a, .a, u {
                @extend %underline-style; } } } }

.newsletter-form__input-wrapper + .newsletter-form__input-wrapper {
    margin-top: $one-line-height; }

.newsletter-form__input-wrapper,
.newsletter-form__actions {

    input {
        width: 100%;
        padding-right: 2em; }

    button[type=submit] {
        @extend %neutral-button;

        &::before {
            content: '→ '; } }

    input:invalid + button[type=submit] {
        cursor: not-allowed; }

    input[type=checkbox] {
        @extend %hide-visually; }

    input[type=checkbox] + label {
        @extend %checkbox-label; }

    input[type=checkbox] + label:hover {
        @extend %checkbox-label--hover; }

    input[type=checkbox]:checked + label {
        @extend %checkbox-label--checked; } }



// ! ---------- FORM ELEMENTS


input,
textarea {
    border: none;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 0;
    padding: 0;

    &:focus {
        outline: none; }

    &::placeholder {
        color: palette('highlight');
        opacity: 1;
        line-height: inherit; } }

input:invalid {
    box-shadow: none; }


button[disabled] {
    cursor: not-allowed !important; }



// ! ---------- NAVIGATION


.navigation {

    color: palette('text');

    ul {
        list-style: none;
        margin-bottom: $one-line-height;
        @extend %fontsize-xl;

        a {
            @include underline-style;
            @include underline-style--large; } }

    li {
        display: inline-block;
        margin-right: .5em;
        font-size: 100%;
        @include breakpoint(small) {
            font-size: 125%; }
        @include breakpoint(medium) {
            font-size: 150%; } }

    .navigation-item--separator {
        display: block;
        margin-bottom: $one-line-height * 0.25; }

    .navigation-item--small {
        @extend %fontsize-m;
        font-weight: normal;
        a {
            @include underline-style--remove; } } }


.navigation__title {
    margin-bottom: $one-line-height; }



// ! ---------- NAVIGATION TOGGLE / BURGER BUTTON

.navigation-toggle {
    @extend %burger-button; }

.navigation__close {
    @extend %close-button;
    position: absolute;
    top: 0;
    right: 0;
    @include global-padding-property(top, $factor: $padding-top-factor);
    @include global-padding-property(right); }



// ! ---------- PAGE TITLE


.page-title {
    @extend %hide-visually; }



// ! ---------- PROJECT LIST / INDEX


.project-list {

    margin-top: $one-line-height * 2;
    padding-bottom: $hover-image-width * 2/3;

    a:hover {
        @extend %underline-style; }

    @include layout--project-list-as-table {
        ul {
            display: inline; }
        li {
            display: inline-block;
            width: 100%;
            padding-left: percentage(1/12);
            &:first-child {
                width: percentage(11/12);
                padding-left: 0; } } } }


.project-list__list + .project-list__list {
    margin-top: $one-line-height;
    @include layout--project-list-as-table {
        margin-top: 0; } }

.project-list__project {
    margin-bottom: $one-line-height / 2;
    @include layout--project-list-as-table {
        margin-bottom: $one-line-height / 4; }

    cursor: default;

    a {
        @extend %underline-style; } }

.project-list__header {
    margin-bottom: $one-line-height / 2;
    @include global-padding(right);
    @include layout--project-list-as-table {
        margin-bottom: 0;
        display: inline-block;
        width: percentage(1/12); } }

.project-list__title {
    display: inline; }

.project-list__category {
    &::after {
        content: ', '; }
    &:last-child {
        &::after {
            content: none; } }

    &::before {
        white-space: nowrap;
        content: '—'; } }

.project-list__category + .project-list__category {
    white-space: normal;
    &::before {
        content: none; } }

.project-list img {
    display: none; }

.hover-image {
    position: fixed;
    top: 0;
    left: 0;
    width: $hover-image-width;
    max-width: 550px;
    z-index: $z-index-hover-img;

    &.square {
        width: $hover-image-width * 5/6; }

    &.portrait {
        width: $hover-image-width * 4/6; }

    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);

    display: none;
    @include breakpoint(medium) {
        [data-whatintent="mouse"] & {
            display: block; } }

    &.is-active {
        opacity: 1; }

    .is-scrolling & {
        opacity: 0 !important; }

    img {
        display: block;
        width: 100%;
        height: auto;
        font-size: inherit;
        transform: translate(#{$one-line-height}, #{-$one-line-height}); } }



// ! ---------- PROJECT INFORMATION


.project-information {
    display: flex;

    display: none;
    .fp-enabled & {
        display: flex; } }

.project-information__pagination {
    flex: 0 0 percentage(1/6);

    span {
        display: block;
        &:first-child {
            margin-bottom: $one-line-height; } } }

.project-information__title {
    flex: 1 1 auto;
    @include global-padding(right); }

@include breakpoint(medium) {
    .project-information__pagination {
        flex-basis: percentage(1/12);
        order: 0; }
    .project-information__title {
        order: 1; } }

.project-text {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: palette('background');
    @extend %scrollable;
    @include global-padding(bottom left);
    @include global-padding(top, $factor: $padding-top-factor);
    transition: transition(opacity transform width);
    z-index: $z-index-project-text;
    @extend %fontsize-s;
    padding-right: percentage(1/6);

    @include breakpoint(small) {
        @include global-padding(right);
        border-left: 2px solid;
        width: percentage(1/2); }
    @include breakpoint(medium) {
        width: percentage(1/3); }
    @include breakpoint(large) {
        width: percentage(1/4); }

    pointer-events: none;
    transform: translateX(100%);

    .is-project-text-visible & {
        opacity: 1;
        pointer-events: auto;
        transform: none; }

    &:empty {
        opacity: 0;
        pointer-events: none; } }

.project-text-toggle {
    @extend %neutral-button;
    position: fixed;
    left: 100%;
    bottom: 0;
    transform: rotate(-90deg) translateZ(2px) translateX(3.25em);
    transform-origin: 0% 100%;
    z-index: $z-index-project-text + 1;
    white-space: nowrap;
    transition: transition(opacity);
    opacity: 0;
    pointer-events: none;
    .project-has-text & {
        opacity: 1;
        pointer-events: auto; }

    span {
        display: inline-block;
        @include global-margin;
        background: black;
        color: white;
        padding: .15em .25em;

        &::before {
            display: inline-block;
            content: '+';
            width: 1em;
            .is-project-text-visible & {
                content: '–';
                transform: scaleX(1.35);
                transform-origin: 0% 50%; } } } }



// ! ---------- PROJECT SLIDESHOW


.project-slideshow {
    transition: transition(opacity);
    opacity: 0;
    .fp-enabled & {
        opacity: 1; } }

.project-slideshow__title {
    @extend %hide-visually; }

.project-slideshow__info {
    @extend %hide-visually; }

.project-slideshow__slide {
    position: relative;

    img, video, .color-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

    figcaption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @extend %fontsize-xxs;
        @include global-padding($factor: 2/3); } }

figure.has-light-caption {
    figcaption {
        color: palette('background'); } }

.project-slideshow__slide-inner {
    position: absolute;

    @include header-height-property(top);
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .fit--contain & {
        @include global-padding-property(left);
        @include global-padding-property(right);
        @include global-padding-property(bottom);
        img, video {
            object-fit: contain; } }

    @include layout--multi-panels-in-slideshow {
        @include global-padding-property(left);
        @include global-padding-property(right);
        @include global-padding-property(bottom);

        .fit--cover:not(.slide--text) & {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; }

        .fit--left & {
            img, video {
                object-fit: contain;
                object-position: 0% 50% !important; } } }

    .slideshow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.panel--text, {
    @extend %fontsize-s;
    @include layout--single-panels-in-slideshow {
        @include global-padding(left);
        @include global-padding(right); }

    .text-block {
        padding-top: $one-line-height--absolute * 2; } }




.project-slideshow__panel {
    flex: 1 1 0;
    position: relative;
    overflow: hidden;

    @include layout--multi-panels-in-slideshow {
        @include global-padding-property(margin-left, $factor: 1/2);
        @include global-padding-property(margin-right, $factor: 1/2);
        &:first-child {
            @include global-padding-property(margin-left, $factor: 0); }
        &:last-child {
            @include global-padding-property(margin-right, $factor: 0); } } }

// Hide all but first one on tiny screens

.project-slideshow__panel + .project-slideshow__panel {
    @include layout--single-panels-in-slideshow {
        display: none; } }



// ! ---------- FULLPAGE ARROWS


.fp-controlArrow {

    width: 50% !important;
    height: 100vh !important;
    border: none !important;

    margin: 0 !important;

    top: 0 !important;

    &.fp-prev {
        left: 0 !important;
        @include retina-cursor('arrow-cursor-left', w-resize, $origin: 25 18); }

    &.fp-next {
        right: 0 !important;
        @include retina-cursor('arrow-cursor-right', e-resize, $origin: 25 18); }

    // Video frames? Leave gap in the middle for click-through into iframe

    .is-viewing-video & {

        width: 42.5% !important; }

    // Touch device or no info about device? Hide arrows to improve swiping
    // On mousemove this automatically goes away

    [data-whatintent="touch"] &,
    [data-whatintent="initial"] & {
        display: none; } }



.gallery-page {
    word-break: break-all;
    color: palette('highlight');
    font-size: $fontsize-xxl;
    font-weight: 200;
    line-height: 1;
    padding: .125em .1em;
    padding-bottom: 0; }

.gallery-page__title {
    @extend %hide-visually; }

.gallery-page__intro {
    min-height: 100vh;
    padding-bottom: 1em;
    font-size: .98em; }

.gallery-page__intro,
.gallery-page__content {
    user-select: none;
    pointer-events: none;
    position: relative;
    z-index: 1;
    p {
        margin-bottom: 1em; } }

.gallery-page__gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: gray;
    z-index: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

    figcaption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @extend %fontsize-xxs;
        @include global-padding($factor: 2/3);
        color: palette('text'); }

    figure.has-light-caption {
        figcaption {
            color: palette('background'); } } }
